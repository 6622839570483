
import React, { useState, useEffect } from 'react'
import { languages, getEmojiFlag, countries  } from 'countries-list';
import companyAPI from '../../http/apiCompany';
import { useCompany } from '../../hooks/CompanyProvider';
import { Input, Layout, Button, Select, Form, Radio, Switch, Divider  } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { CheckOutlined, CloseOutlined, EyeTwoTone, EyeInvisibleOutlined  } from '@ant-design/icons';
import currencies from './currency-list.json';

const { Content } = Layout;
const { Option } = Select;

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 16 },
      sm: { span: 12 },
    },
  };
  const tailLayout = {
    wrapperCol: { offset: 2, span: 16 },
  };

function Settings(props){
    
    const [form] = Form.useForm(); 
    const [selectedLanguages, setSelectedLanguages] = useState([]);
   
    useEffect(() => {
        if (!props.company || !props.company.data)
            return;
        
        form.setFieldsValue({
            vat: props.company.data.vat,
            isVat2: props.company.data.isVat2 || false,
            vat2: props.company.data.vat2,
            vat2Name: props.company.data.vat2Name,
            vatNo: props.company.data.vatNo,
            registerCode: props.company.data.registerCode,
            legalAddress: props.company.data.legalAddress,
            legalPhone: props.company.data.legalPhone,
            legalEmail: props.company.data.legalEmail,
            legalWWW: props.company.data.legalWWW,
            currency: props.company.data.currency,
            country: props.company.data.country,
            language: props.company.data.language,
            isWhiteLabel: props.company.data.isWhiteLabel || false,
            isDisableSplash: props.company.data.isDisableSplash || false,
            languages: props.company.data && props.company.data.languages ? props.company.data.languages.map(l => l.key) : [],
            compucashClientId: props.company.data.compucashClientId,
            compucashClientSecret: props.company.data.compucashClientSecret,
            isCompucashEnabled: props.company.data.isCompucashEnabled,
            posterToken: props.company.data.posterToken,
            posterClientId: props.company.data.posterClientId,
            stripeId: props.company.stripeId,
            callbackUrl: props.company.data.callbackUrl,
            callbackKey: props.company.data.callbackKey,
            payStackId: props.company.payStackId,
            isPosterEnabled: props.company.data.isPosterEnabled,
            isCallbackEnabled: props.company.data.isCallbackEnabled
        });

        setSelectedLanguages(props.company.data && props.company.data.languages ? props.company.data.languages.map(l => l.key) : [])
    },[props.companyId])


    const setupPayment = async(companyId) => {
        const link = await companyAPI.createStripeAccount(companyId);
        window.location.href = link; 
    }
    

    const onFinish = (value) => {
        
        let newData = {
            vat: value.vat,
            isVat2: value.isVat2,
            vat2: value.vat2,
            vat2Name: value.vat2Name,
            registerCode: value.registerCode,
            vatNo: value.vatNo,
            legalAddress: value.legalAddress,
            legalPhone: value.legalPhone,
            legalEmail: value.legalEmail,
            legalWWW: value.legalWWW,
            currency: value.currency,
            country: value.country,
            language: value.language,
            isWhiteLabel: value.isWhiteLabel,
            isDisableSplash: value.isDisableSplash,
            compucashClientId: value.compucashClientId,
            compucashClientSecret: value.compucashClientSecret,
            isCompucashEnabled: value.isCompucashEnabled,
            posterToken: value.posterToken,
            posterClientId: value.posterClientId,
            isPosterEnabled: value.isPosterEnabled,
            isCallbackEnabled: value.isCallbackEnabled,
            callbackUrl: value.callbackUrl,
            callbackKey: value.callbackKey,
            languages: value.languages.map(l =>{
                return {
                    key: l,
                    ...languages[l]
                };
            })
        }
        
        props.onUpdate({
            id: props.company.id,
            stripeId: value.stripeId,
            data: newData,
            payStackId: value.payStackId,
        })
      }

      const handleFormValuesChange = (changedValues) => {
        const formFieldName = Object.keys(changedValues)[0];
        if (formFieldName === "languages") {
          setSelectedLanguages(changedValues[formFieldName]);  // perform setState here
         
        }
      };


    return (
            <div style={{ padding: '15px 20px', marginTop: 5, width: "100%"}}>
                {
                    props.company 
                    ?  
                    <>
                     <PageHeader
                        className="site-page-header"
                        title={props.company.name}
                        subTitle="settings"
                    />
                    <Form
                        key={props.companyId}
                        form={form}
                        {...formItemLayout}
                        name="setting"
                        id="settingForm"
                        onFinish={onFinish}
                        onValuesChange={handleFormValuesChange}
                        scrollToFirstError
                    >
                    <Divider orientation="left">General information</Divider>
                    <Form.Item
                        name="country"
                        label={`Country`}
                        rules={[
                        {
                            required: true,
                            message: 'Please select country',
                        },
                        ]}
                    >
                        <Select
                            mode="single"
                            allowClear
                            style={{ width: '200px' }}
                            placeholder="Select currency for and products"
                        >
                            { Object.keys(countries).map(l => <Option key={l} value={l}> {countries[l].name}</Option>) }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="registerCode"
                        label={`Register Code`}
                        rules={[
                        {
                            required: true,
                            message: 'Please input register code',
                        },
                        ]}
                    >
                    <Input type="text" style={{width:"200px"}}/>
                    </Form.Item>
                    <Form.Item
                        name="legalAddress"
                        label={`Address`}
                        rules={[
                        {
                            required: true,
                            message: 'Please input legal address',
                        },
                        ]}
                    >
                    <Input type="text" style={{width:"200px"}}/>
                    </Form.Item>
                    <Form.Item
                        name="legalPhone"
                        label={`Phone`}
                        rules={[
                        {
                            required: true,
                            message: 'Please input phone',
                        },
                        ]}
                    >
                    <Input type="phone" style={{width:"200px"}}/>
                    </Form.Item>
                    <Form.Item
                        name="legalEmail"
                        label={`Email`}
                        rules={[
                        {
                            required: true,
                            message: 'Please input email',
                        },
                        ]}
                    >
                    <Input type="email" style={{width:"200px"}}/>
                    </Form.Item>
                    <Form.Item
                        name="legalWWW"
                        label={`Website`}
                        rules={[
                        {
                            required: true,
                            message: 'Please input website',
                        },
                        ]}
                    >
                    <Input type="text" style={{width:"200px"}}/>
                    </Form.Item>
                    <Form.Item
                        name="vatNo"
                        label={`VAT No`}
                        rules={[
                        {
                            required: true,
                            message: 'Please input vat no',
                        },
                        ]}
                    >
                    <Input type="text" style={{width:"200px"}}/>
                    </Form.Item>
                    <Form.Item
                        name="vat"
                        label={`VAT`}
                        rules={[
                        {
                            required: true,
                            message: 'Please input vat',
                        },
                        ]}
                    >
                    <Input type="number" suffix="%" style={{width:"100px"}}/>
                    </Form.Item>
                    <Form.Item
                        name="currency"
                        label={`Currency`}
                        rules={[
                        {
                            required: true,
                            message: 'Please select currency',
                        },
                        ]}
                    >
                        <Select
                            mode="single"
                            allowClear
                            style={{ width: '200px' }}
                            placeholder="Select currency for and products"
                        >
                            { Object.keys(currencies).map(l => <Option key={l} value={l}> {currencies[l].name} {currencies[l].symbol_native}</Option>) }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="isWhiteLabel"
                        valuePropName="checked"
                        label={`Whitelabel`}
                    >
                        <Switch
                            defaultChecked={false}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                        />
                    </Form.Item>
                    <Form.Item
                        name="isDisableSplash"
                        valuePropName="checked"
                        label={`Disable SplashScreen`}
                    >
                        <Switch
                            defaultChecked={false}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                        />
                    </Form.Item>
                    
                    <Divider orientation="left">Additional VAT</Divider>
                    
                    <Form.Item
                        name="isVat2"
                        valuePropName="checked"
                        label={`Use additional VAT`}
                    >
                        <Switch
                            defaultChecked={false}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                        />
                    </Form.Item>
                    <Form.Item
                        name="vat2"
                        label={`VAT 2`}
                    >
                    <Input type="number" suffix="%" style={{width:"100px"}}/>
                    </Form.Item>
                    <Form.Item
                        name="vat2Name"
                        label={`VAT 2 Name`}
                    >
                    <Input type="text" style={{width:"200px"}}/>
                    </Form.Item>

                    <Divider orientation="left">Languages</Divider>
                    
                    <Form.Item
                        name="languages"
                        label={`Languages`}
                        rules={[
                        {
                            required: true,
                            message: 'Please select languages',
                        },
                        ]}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '500px' }}
                            placeholder="Select languages for menu and products"
                        >
                            { Object.keys(languages).map(l => <Option key={l} value={l}>{getEmojiFlag(l)} {languages[l].name}</Option>) }
                        </Select>
                    </Form.Item>
                    <Form.Item
                      name="language"
                      label={`Default`}
                      rules={[
                        {
                            required: true,
                            message: 'Please select ui default language',
                        }
                      ]}
                    >
                     <Radio.Group>
                    { selectedLanguages.map(l => <Radio.Button key={l} value={l}>{languages[l].name}</Radio.Button>) }
                    </Radio.Group>
                    </Form.Item>
                    
                    <Divider orientation="left">Stripe</Divider>
                    <Form.Item
                      name="stripeId"
                      label={`Stripe account id`}
                    >
                        <Input type="text" style={{width:"300px"}} placeholder='Clients stripe account id'/> 
                    </Form.Item>
                    <Divider orientation="left">Paystack</Divider>
                    <Form.Item
                        name="payStackId"
                        label={`Paystack subaccount id`}
                    >
                        <Input type="text" style={{width:"300px"}} placeholder="Clients paystack subaccount id"/>
                    </Form.Item>
                    
                    <Divider orientation="left">External system callback</Divider>

                    <Form.Item
                      name="callbackUrl"
                      label={`Callback URL`}
                    >
                        <Input type="text" placeholder='Endpoint to push orders to after completion'/> 
                    </Form.Item>
                    <Form.Item
                      name="callbackKey"
                      label={`Callback Key`}
                    >
                        <Input type="text" style={{width:"300px"}} placeholder='Callaback key'/> 
                    </Form.Item>
                    <Form.Item
                        name="isCallbackEnabled"
                        valuePropName="checked"
                        label={`Enable integration`}
                        rules={[
                        {
                            required: true,
                            message: 'Please choose what ever integration with custom system is enabled',
                        },
                        ]}
                    >
                        <Switch
                            defaultChecked={false}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                        />
                    </Form.Item>

                    <Divider orientation="left">Compucash</Divider>
                    <Form.Item
                        name="compucashClientId"
                        label={`ClientId`}
                        placeholder="CompuCash client id"
                    >
                    <Input type="text" style={{width:"200px"}}/>
                    </Form.Item>
                    <Form.Item
                        name="compucashClientSecret"
                        label={`ClientSecret`}
                    >
                    <Input.Password
                            placeholder="CompuCash client secret"
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                    </Form.Item>
                    <Form.Item
                        name="isCompucashEnabled"
                        valuePropName="checked"
                        label={`Enable integration`}
                        rules={[
                        {
                            required: true,
                            message: 'Please choose what ever integration with compucash is enabled',
                        },
                        ]}
                    >
                        <Switch
                            defaultChecked={false}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                        />
                    </Form.Item>

                    <Divider orientation="left">Poster</Divider>
                    <Form.Item
                        name="posterClientId"
                        label={`Poster Client ID`}
                    >
                        <Input placeholder="Client ID for online orders"/>
                    </Form.Item>
                    <Form.Item
                        name="posterToken"
                        label={`Poster Token`}
                    >
                        <Input.Password
                            placeholder="Personal Poster access token"
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="isPosterEnabled"
                        valuePropName="checked"
                        label={`Enable integration`}
                        rules={[
                        {
                            required: true,
                            message: 'Please choose what ever integration with Poster is enabled',
                        },
                        ]}
                    >
                        <Switch
                            defaultChecked={false}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                        />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Form.Item>
                   

                </Form>
                </>
                    : ''
                }
              
            </div>
            
    )
}


export default function SettingList(props){
    const company = useCompany();
    
    const onUpdate = async(update) => {
        const companyUpdated = await companyAPI.update(update);
        company.updateCompany(companyUpdated);
    }

    return (
        <Content className="site-layout" style={{borderRadius: "10px"}}>
           
            <Settings  company={company.companyList.find(c => c.id === company.companyId)} onUpdate={onUpdate} companyId={company.companyId} />
            {/*{company.companyList.map(c => <Settings company={c} onUpdate={onUpdate} companyId={c.id}/>)}*/}
        </Content>
    )
} 