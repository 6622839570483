
import React, { useState, useEffect } from 'react'
import '@firebase/auth';
import '@firebase/storage';
import {
  Modal,
  Form,
  Input,
  Switch,
  Divider,
  InputNumber 
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { nanoid } from 'nanoid'

import tableAPI
 from '../../http/apiTable';
const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

export default function TableEdit(props){

    const [form] = Form.useForm(); 
    const [confirmLoading, setConfirmLoading] = useState(false);
    
    useEffect(()=> {
        form.resetFields(); 
        if (props.menuLocationId && props.isModalOpen === true){
            
            form.setFieldsValue({
                name: props.menuLocation.name,
                qr: props.menuLocation.data.qr,
                slogan: props.menuLocation.data.slogan,
                externalId: props.menuLocation.data.externalId,
                externalPosId: props.menuLocation.data.externalPosId,
                isTable: props.menuLocation.data.isTable || false,
                isKapp: props.menuLocation.data.isKapp || false,
                isAllowOrder: props.menuLocation.data.isAllowOrder || false,
                isSimpleOrder: props.menuLocation.data.isSimpleOrder || false,
                isAllowTakeaway: props.menuLocation.data.isAllowTakeaway || false,
                isAllowCash: props.menuLocation.data.isAllowCash || false,
                isAllowCards: props.menuLocation.data.isAllowCards || false,
                isQuickSelect: props.menuLocation.data.isQuickSelect || false,
                isShowBottomMenu: props.menuLocation.data.isShowBottomMenu || false,
                isCompleteOrderAfterInsert: props.menuLocation.data.isCompleteOrderAfterInsert || false,
                isSelectStages: props.menuLocation.data.isSelectStages || false,
                isAskForNameBeforeOrder: props.menuLocation.data.isAskForNameBeforeOrder || false,
                isAskForPhoneBeforeOrder: props.menuLocation.data.isAskForPhoneBeforeOrder || false,
                isAskForEmailBeforeOrder: props.menuLocation.data.isAskForEmailBeforeOrder || false,
                isAskForSeatBeforeOrder: props.menuLocation.data.isAskForSeatBeforeOrder || false,
                isAllowCallWaiter: props.menuLocation.data.isAllowCallWaiter || false,
                isHideNameInHeader: props.menuLocation.data.isHideNameInHeader || false,
                isHideTimer: props.menuLocation.data.isHideTimer || false,
                isMsOrder: props.menuLocation.data.isMsOrder || false,
            });
        }else{
            
        }
    },[props.isModalOpen]);

    const saveMenuLocations = async(values) => {
        setConfirmLoading(true);
        
        let apiObj = {
            name: values.name,
            data: {
                qr: values.qr,
                slogan: values.slogan,
                externalId: values.externalId,
                externalPosId: values.externalPosId,
                isTable: values.isTable,
                isKapp: values.isKapp,
                isAllowOrder: values.isAllowOrder,
                isSimpleOrder: values.isSimpleOrder,
                isAllowTakeaway: values.isAllowTakeaway,
                isAllowCash: values.isAllowCash,
                isAllowCards: values.isAllowCards,
                isQuickSelect: values.isQuickSelect,
                isShowBottomMenu: values.isShowBottomMenu,
                isCompleteOrderAfterInsert: values.isCompleteOrderAfterInsert,
                isSelectStages: values.isSelectStages,
                isAskForNameBeforeOrder: values.isAskForNameBeforeOrder,
                isAskForPhoneBeforeOrder: values.isAskForPhoneBeforeOrder,
                isAskForEmailBeforeOrder: values.isAskForEmailBeforeOrder,
                isAskForSeatBeforeOrder: values.isAskForSeatBeforeOrder,
                isAllowCallWaiter: values.isAllowCallWaiter,
                isHideNameInHeader: values.isHideNameInHeader,
                isHideTimer: values.isHideTimer,
                isMsOrder: values.isMsOrder,
            },
            deleted: false
        };
        console.log('apiObj', apiObj);
        console.log(`${values.isBulk}:${values.isBulk === true} ${values.bulkCount}:${values.bulkCount > 1}`)
        
        let result;
        if (values.isBulk && values.bulkCount > 1){
            const name = apiObj.name;
            for(let i = 0; i < values.bulkCount; ++i) {
                apiObj.name = `${name} ${i+1}`;
                apiObj.data.qr = nanoid();
                await createUpdateQr(apiObj);
            }
            result = true;
        } else {
            result = await createUpdateQr(apiObj);
        }
        
        setConfirmLoading(false);
        if (result !== false) {
            setConfirmLoading(false);
            form.resetFields(); 
            props.setModalOpen(false);
            props.onSuccess(result);
        }
    }

    const createUpdateQr = async(apiObj) => {
        if (!props.menuLocationId){
            return await tableAPI.create(props.menuId, apiObj);
        }else{
            return await tableAPI.update(props.menuLocationId, apiObj);
        }
    }

    const handleOk = () => {
        
        form
          .validateFields()
          .then(values => {
           
            saveMenuLocations(values);

          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });

       
      };

    const handleCancel = () => {
        form.resetFields(); 
        props.setModalOpen(false);
    };

    return (
        <Modal
            destroyOnClose={true}
            title={!props.id ? "Create" : "Update"}
            open={props.isModalOpen}
            forceRender
            confirmLoading={confirmLoading}
            onOk={handleOk}
            onCancel={handleCancel}
        >
           
            <Form
                key={props.id}
                form={form}
                {...formItemLayout}
                name="qr"
                id="qrForm"
                scrollToFirstError
            >
            <Form.Item
                name="name"
                label={`Name`}
                rules={[
                {
                    required: true,
                    message: 'Please input name',
                },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name="slogan"
                label={`Welcome page slogan`}
                rules={[
                {
                    required: false
                },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name="externalId"
                label={`Table Id`}
                rules={[
                {
                    required: false
                },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name="externalPosId"
                label={`Pos Id`}
                rules={[
                {
                    required: false
                },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name="qr"
                label={`QR Code`}
                rules={[
                {
                    required: true,
                    message: 'Please input qr code',
                },
                ]}
            >
                <Input/>
            </Form.Item>
            
            <div>
                <Divider plain>Ordering</Divider>
                <p>
                <Form.Item
                    name="isAllowOrder"
                    valuePropName="checked"
                    initialValue={false}
                    label={`Allow Ordering`}
                    rules={[
                    {
                        required: true,
                        message: 'Please choose what ever it is allowed to order',
                    },
                    ]}
                >
                    <Switch
                        size="small"
                        defaultChecked={false}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Form.Item>
                <Form.Item
                    name="isSimpleOrder"
                    valuePropName="checked"
                    initialValue={false}
                    label={`Simple Ordering`}
                    rules={[
                    {
                        required: true,
                        message: 'Please choose what ever it is simple flow',
                    },
                    ]}
                >
                    <Switch
                        size="small"
                        defaultChecked={false}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Form.Item>
                <Form.Item
                    name="isTable"
                    valuePropName="checked"
                    initialValue={false}
                    label={`Eating seat`}
                    rules={[
                    {
                        required: true,
                        message: 'Please choose if it is eating seat',
                    },
                    ]}
                >
                    <Switch
                        size="small"
                        defaultChecked={false}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Form.Item>
                
                <Form.Item
                    name="isQuickSelect"
                    valuePropName="checked"
                    initialValue={false}
                    label={`Quick Select`}
                    rules={[
                    {
                        required: true,
                        message: 'Please choose what ever it is allowed to add items from main list',
                    },
                    ]}
                >
                    <Switch
                        size="small"
                        defaultChecked={false}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Form.Item>
               
                <Form.Item
                    name="isSelectStages"
                    valuePropName="checked"
                    initialValue={false}
                    label={`Allow to select course order`}
                    rules={[
                    {
                        required: true,
                        message: 'Please choose what ever your customer allowed to select order of courses',
                    },
                    ]}
                >
                    <Switch
                        size="small"
                        defaultChecked={false}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Form.Item>
                <Form.Item
                    name="isAllowCallWaiter"
                    valuePropName="checked"
                    initialValue={false}
                    label={`Show call waiter button`}
                    rules={[
                    {
                        required: true,
                        message: 'Please choose what ever your customer allowed to call waiter from app',
                    },
                    ]}
                >
                    <Switch
                        size="small"
                        defaultChecked={false}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Form.Item>

                </p>
            </div>

            <div className=''>
                <Divider plain>
                    Checkout
                </Divider>
                <p>
                <Form.Item
                    name="isAskForNameBeforeOrder"
                    valuePropName="checked"
                    initialValue={false}
                    label={`Ask for name before order`}
                    rules={[
                    {
                        required: true,
                        message: 'Please choose what ever you want to ask for name before submitting order',
                    },
                    ]}
                >
                    <Switch
                        size="small"
                        defaultChecked={false}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Form.Item>
                <Form.Item
                    name="isAskForPhoneBeforeOrder"
                    valuePropName="checked"
                    initialValue={false}
                    label={`Ask for phone before order`}
                    rules={[
                    {
                        required: true,
                        message: 'Please choose what ever you want to ask for phone before submitting order',
                    },
                    ]}
                >
                    <Switch
                        size="small"
                        defaultChecked={false}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Form.Item>
                <Form.Item
                    name="isAskForEmailBeforeOrder"
                    valuePropName="checked"
                    initialValue={false}
                    label={`Ask for email before order`}
                    rules={[
                    {
                        required: true,
                        message: 'Please choose what ever you want to ask for email before submitting order',
                    },
                    ]}
                >
                    <Switch
                        size="small"
                        defaultChecked={false}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Form.Item>
                <Form.Item
                    name="isAskForSeatBeforeOrder"
                    valuePropName="checked"
                    initialValue={false}
                    label={`Ask for seat nr before order`}
                    rules={[
                    {
                        required: true,
                        message: 'Please choose what ever you want to ask for seat number before submitting order',
                    },
                    ]}
                >
                    <Switch
                        size="small"
                        defaultChecked={false}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Form.Item>
                <Form.Item
                    name="isAllowTakeaway"
                    valuePropName="checked"
                    initialValue={false}
                    label={`Allow Takeaway`}
                    rules={[
                    {
                        required: true,
                        message: 'Please choose if allowed to order takeaway',
                    },
                    ]}
                >
                    <Switch
                        size="small"
                        defaultChecked={false}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Form.Item>
                </p>
            </div>
            <div>
                <Divider plain>Payment</Divider>
                <p>
                <Form.Item
                    name="isAllowCash"
                    valuePropName="checked"
                    initialValue={false}
                    label={`Allow Cash`}
                    rules={[
                    {
                        required: true,
                        message: 'Please choose if cash payments are allowed',
                    },
                    ]}
                >
                    <Switch
                        size="small"
                        defaultChecked={false}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Form.Item>
                <Form.Item
                    name="isAllowCards"
                    valuePropName="checked"
                    initialValue={false}
                    label={`Allow Stripe`}
                    rules={[
                    {
                        required: true,
                        message: 'Please choose if stripe payments are allowed',
                    },
                    ]}
                >
                    <Switch
                        size="small"
                        defaultChecked={false}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Form.Item>
                </p>
            </div>
            
           
           <div>
                <Divider plain>Visuals</Divider>
                <p>
                <Form.Item
                    name="isShowBottomMenu"
                    valuePropName="checked"
                    initialValue={false}
                    label={`Show Bottom menu`}
                    rules={[
                    {
                        required: true,
                        message: 'Please choose what ever you want bottom menu',
                    },
                    ]}
                >
                    <Switch
                        size="small"
                        defaultChecked={false}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Form.Item>
                </p>
                <p>
                <Form.Item
                    name="isHideNameInHeader"
                    valuePropName="checked"
                    initialValue={false}
                    label={`Hide name in header`}
                    rules={[
                    {
                        required: true,
                        message: 'Please choose what ever you want to hide name in header',
                    },
                    ]}
                >
                    <Switch
                        size="small"
                        defaultChecked={false}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Form.Item>
                </p>
                <p>
                <Form.Item
                    name="isHideTimer"
                    valuePropName="checked"
                    initialValue={false}
                    label={`Hide Timer after order`}
                    rules={[
                    {
                        required: true,
                        message: 'Please choose what ever you want to hide timer',
                    },
                    ]}
                >
                    <Switch
                        size="small"
                        defaultChecked={false}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Form.Item>
                </p>
           </div>
           <div>
                <Divider plain>Others</Divider>
                <p>
                <Form.Item
                    name="isKapp"
                    valuePropName="checked"
                    initialValue={false}
                    label={`Self Service`}
                    rules={[
                    {
                        required: true,
                        message: 'Please choose if it is self service',
                    },
                    ]}
                >
                    <Switch
                        size="small"
                        defaultChecked={false}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Form.Item>
                <Form.Item
                    name="isMsOrder"
                    valuePropName="checked"
                    initialValue={false}
                    label={`MS Delivery`}
                    rules={[
                    {
                        required: true,
                        message: 'Please choose if it is Mängusaar special',
                    },
                    ]}
                >
                    <Switch
                        size="small"
                        defaultChecked={false}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Form.Item>
                <Form.Item
                    name="isCompleteOrderAfterInsert"
                    valuePropName="checked"
                    initialValue={false}
                    label={`Complete after insert`}
                    rules={[
                    {
                        required: true,
                        message: 'Please choose what ever you want to complete order after insert',
                    },
                    ]}
                >
                    <Switch
                        size="small"
                        defaultChecked={false}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Form.Item>
                </p>
            </div>

            
            <Divider/>
            <Form.Item label="Bulk" extra="Auto generate several QR codes with same settings">
                <div className='flex space-x-3'>
                <Form.Item
                    name="bulkCount"
                    initialValue={3}
                >
                    <InputNumber min={1} max={50} defaultValue={3}  />
                </Form.Item>
                <Form.Item
                    name="isBulk"
                    valuePropName="checked"
                    initialValue={false}
                    label={`do it?`}
                >
                    <Switch
                        size="small"
                        defaultChecked={false}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Form.Item>
                </div>
            </Form.Item>
            </Form>

        </Modal>
    )
}