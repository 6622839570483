
import React, { useEffect, useState } from 'react'
import { useProduct } from '../../hooks/ProductProvider';

import { DeleteTwoTone, EditTwoTone, GroupOutlined, PlusCircleTwoTone } from '@ant-design/icons';
import { Switch, Input, Layout, Button, Popconfirm, Row, Col, Card, List, Popover, Select   } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import categoryAPI from '../../http/apiCategory';
import MenuConfigurationGroup from './MenuConfigurationGroup';
import { useCompany } from '../../hooks/CompanyProvider';
import menuAPI from '../../http/apiMenu';

import { formatCurrencty } from '../Common';

import { getItemStyle, getListStyle, move, reorder } from '../CommonDraggable'
import Product from '../Products/Product';


function MenuCategoryColumn(props){
    const [category, setCategory] = useState(props.category);
    const company = useCompany();
    const [productSerachOpen, setProductSerachOpen] = useState(false);

    useEffect(() => {
        setCategory(props.category);
    }, [props.category])

    const showProductList = () => {
        return <div className='flex justify-between'>
            <div className='w-64'>
            <Select
                showSearch
                placeholder="Select a product"
                style={{ width: '100%' }}
                optionFilterProp="children"
                onChange={(e) => { props.onProductButtonAdd(category.id, e); setProductSerachOpen(false);  }}
                //onSearch={onSearch}
                filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={
                    (props.products || []).map(p => {
                        return { value: p.id, label: p.data.name[company.language]}
                    })
                    }/>
            </div>
            <div>
                <Button type='link' onClick={() => { }}>close</Button>
            </div>
        </div>;
    }

    const showProductListHandleOpenChange = (newOpen) => {
        setProductSerachOpen(newOpen);
    };

    const changeCategoryVisibility = async(categoryId) => {
        let result = await categoryAPI.toggleCategoryVisibility(props.category.id);
        if (result === false)
            return;

        setCategory(
            {
                ...category,
            }
        );
        props.onCategoryVisibilityChange(category.id);
    }

    const changeProductVisibility = async(productId) => {
        let result = await categoryAPI.toggleProductVisibility(props.category.id, productId);
        if (result === false)
            return;
        const newProducts = [...category.products.map(p => {
            if (p.id === productId)
                return {...p, visible: !p.visible }
            
            return p;
        })];

        setCategory(
            {
                ...category,
                products: [...newProducts]
            }
        );
        props.onProductVisibilityChange(category.id, productId);
    }

    const getMultiLang = (data, field) => {
        if (!data || !field)
            return '';

        var multiLangStr = Object.keys(data[field]);
        if (multiLangStr.length > 0){
            let eeIndex = multiLangStr.indexOf(props.language);
            return data[field][eeIndex > -1 ? multiLangStr[eeIndex] : multiLangStr[0]];
        }
        else
            return '';
    }

    return (
        
        <Draggable draggableId={`${props.category.id}_${props.shortCategory}`} key={`${props.category.id}_${props.shortCategory}`} index={props.index}>
                                {
                                     (provided, snapshot) => (
                                        <div
                                            key={`${props.category.id}_${props.shortCategory}_draggable_group`}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >

             
             {
                  props.shortCategory 
                  ? <div className='border-1 shadow-md rounded-lg p-3'>{`${getMultiLang(category.data, 'name')} ${category.data.hours && category.data.hours.length === 2 ? `(${category.data.hours[0]} - ${category.data.hours[1]})` : ''}`}</div>
                  : <Card 
                  size="small" 
                  key={`${category.id}_${props.shortCategory}`}
                  title={`${getMultiLang(category.data, 'name')} ${category.data.hours && category.data.hours.length === 2 ? `(${category.data.hours[0]} - ${category.data.hours[1]})` : ''}`} 
                  
                  style={{ width: 250, margin: "10px 10px", borderRadius: "10px"}}
              >
                <div className='flex justify-between'>
                        <div>
                           <Switch
                              size="small"
                              defaultChecked={true}
                              checked={category.visible}
                              onChange={() => changeCategoryVisibility(category.id)}
                            />
                        </div>
                        <div>
                            <Popover content={showProductList()} onOpenChange={showProductListHandleOpenChange} open={productSerachOpen} title="Search for product" trigger="click">
                                <Button type="link" icon={<PlusCircleTwoTone />}></Button>
                            </Popover>
                        </div>
                        <div>
                            <Button type="link" icon={<EditTwoTone/>} onClick={() => props.onEdit(props.category)}></Button>
                        </div>
                        <div>
                            <Popconfirm
                                title="Are you sure?"
                                onConfirm={() => props.onDelete(props.category.id)}
                            >
                                <Button type="link" icon={<DeleteTwoTone twoToneColor="#eb2f96"/>}></Button>
                            </Popconfirm>
                        </div>
                </div>
              {
                 <Droppable key={`droppable_${category.id}`} droppableId={category.id} type="product">
                  {(provided, snapshot) => (
                  <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}>
                      <List
                          dataSource={category.products}
                          renderItem={(item,index) => (
                          <Draggable
                              key={item.id}
                              draggableId={`${item.id}_${category.id}`}
                              index={index}

                          >
                          {(provided, snapshot) => (
                              <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                  )}
                                  
                              >
                                  {provided.placeholder}
                                  <div className="p-3 shadow-md rounded-lg"
                                      key={item.id}
                                      ref={provided.innerRef}
                                      style={getListStyle(snapshot.isDraggingOver)}
                                  >
                                      <div>{item.data.name[props.language]}</div>
                                      <div className="flex justify-between pt-2">
                                          <div>{formatCurrencty(item.data.gross, company.currency)}</div>
                                          
                                          <div>

                                          <a className='pr-2' onClick={() => props.productEdit(category.id, item.id)}><EditTwoTone /></a>
                        
                                          <Switch
                                              size="small"
                                              defaultChecked={true}
                                              checked={item.visible}
                                              onChange={() => changeProductVisibility(item.id)}
                                          />
                                            <Popconfirm
                                                title="Are you sure?"
                                                onConfirm={() => props.onProductButtonRemove(props.category.id, item.id) }
                                                >
                                                    <Button type="link" icon={<DeleteTwoTone twoToneColor="#eb2f96"/>}></Button>
                                                </Popconfirm>
                                          </div>
                                          
                                      </div>
                                  </div>
                              
                              </div>
                          )}
                          </Draggable>

                      )}/>
                  </div>
                  )}
                  </Droppable>
              }
              
          </Card>
             }               
            
            {provided.placeholder}
                                        </div>
                                        
                                     )
                                }
                               
                            </Draggable>
                           
                            
        
    );
}

export default function MenuConfiguration(props){
    const products = useProduct();
    const company = useCompany();
    const [menuId, setMenuId] = useState();
    const [categories, setCategories] = useState();
    //const [menuLocations, setMenuLocations] = useState();
    const [loading, setLoading] = useState(true);
    const [productSearchText, setProductSearchText] = useState('');
    
    const [productList, setProductList] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState();
    const [groupModalOpen, setGroupModalOpen] = useState(false);
    
    const [openProductEdit, setOpenProductEdit] = useState(false);
    const [productId, setProductId] = useState(null);

    const [hideConfigColumns, setHideConfigColumns] = useState(false);

    useEffect(() => {
        setLoading(false);
        let productsInGroups = [];
        if (categories && categories.length > 0)
            productsInGroups = categories.map(c => c.products).flat();

        setProductList(
            [...products.productList.filter(p => !productsInGroups.some(p2 => p2.id === p.id)).map(p2 => { p2.visible = true; return p2;})]
        )
    },[categories])
    
    useEffect(() => {
        fetchData(props.data.id);
    }, [props.data.id])

    const fetchData = async(menuId) =>{
        setLoading(true);
        let response = await menuAPI.get(menuId);
        if (response == null){
            setLoading(false);
            return;
        }

        setMenuId(response.id);
        setCategories(response.categories);
        //setMenuLocations(response.menuLocations);

        setLoading(false);
    }

    const createGroup = (group) => {
        setSelectedGroup(group);
        setGroupModalOpen(true);
    }

    const deleteGroup = async(groupId) => {
        await categoryAPI.toggleDelete(groupId);
        
        setCategories([...categories.filter(c => c.id !== groupId)]);
        
    }

    const onSuccessGroupSave = (group) => {
        let newCategories = [];
        let existing = categories.find(c => c.id === group.id);
        if (existing){
            newCategories = categories.map(c => {
                if (c.id === group.id){
                    return {...group, products: c.products};
                }
                return c;
            })
        }else{
            newCategories = [...categories, {...group}];
        }
        setSelectedGroup();
        setGroupModalOpen(false);
        setCategories([...newCategories ]);
        
    }

    const onFailGroupSave = () => {

    }

    const getCategoryList = () => {
        return categories;
    }

    const setCategoryList = (newCategories) => { 
        setCategories(
            [...newCategories]
        )
    }

    const getProductList = (groupId) => {
        
        if (groupId === 'products')
            return [...productList.filter(p => searchMultiLang(p, productSearchText))];

        if (!categories || categories.length === 0)
            return [];

        return categories.find(c => c.id === groupId).products;
    }

    const setProdcutList = (groupId, products, groupId2, products2) => {
        
        if (groupId === 'products'){
            setProductList([...products]);
        }

        if (groupId2 === 'products'){
            setProductList([...products2]);
        }
        
        const newCategories = categories.map(c => {
            if (c.id === groupId){
                return {...c, products: [...products]}
            }
            if (groupId2 && c.id === groupId2)
                return {...c, products: [...products2]}
            
            return c;
        });

        setCategories([...newCategories]);
    }

    const onProductVisibilityChange = (groupId, productId) => {
        
        const newCategories = categories.map(c => {
            if (c.id === groupId) {
                return {...c, products: [...c.products.map(p => {
                    if (p.id === productId)
                        return {...p, visible: !p.visible }
                    return p;
                })]}
            } 
            return c;
        });

        setCategories([...newCategories]);
    }

    const onCategoryVisibilityChange = (groupId) => {
        const newCategories = categories.map(c => {
            if (c.id === groupId) {
               return {...c, visible: !c.visible }
            } 
            return c;
        });

        setCategories([...newCategories]);
    }

    const onProductButtonAdd = (categoryId, productId) => {
        categoryAPI.addProduct(categoryId, productId, 0);
        
        const source = { droppableId: 'products' }
        const sourceBefore = getProductList(source.droppableId);
        const desitinationBefore = getProductList(categoryId);
        const result = move(
            sourceBefore,
            desitinationBefore,
            { droppableId: 'products', index: sourceBefore.findIndex(p => p.id === productId) },
            { droppableId: categoryId, index: 0 }
        );
        setProdcutList(source.droppableId, result[source.droppableId] || [], categoryId, result[categoryId] || []);         
    }

    const onProductButtonRemove = (categoryId, productId) => {
        categoryAPI.removeProduct(categoryId, productId);
        
        const source = { droppableId: categoryId }
        const sourceBefore = getProductList(source.droppableId);
        const desitinationBefore = getProductList('products');
        const result = move(
            sourceBefore,
            desitinationBefore,
            { droppableId: categoryId, index: sourceBefore.findIndex(p => p.id === productId) },
            { droppableId: 'products', index: 0 }
        );

        setProdcutList(source.droppableId, result[source.droppableId] || [], categoryId, result[categoryId] || []);     
    }

    const onDragEnd = (result) => {
        const { source, destination, type } = result;

        if (type === 'group' && destination && source){
            let sourceBefore = getCategoryList();
            const category = sourceBefore[source.index];

            const items = reorder(
                sourceBefore,
                source.index,
                destination.index
            );
            
            setCategoryList(items);
            menuAPI.updateCategoryOrder(menuId, category.id, destination.index)
            return;
        }
            
        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId && source.droppableId !== 'products') {
            const sourceBefore = getProductList(source.droppableId);
            const product = sourceBefore[source.index];
            const items = reorder(
                sourceBefore,
                source.index,
                destination.index
            );

            categoryAPI.updateProductOrder(source.droppableId, product.id, destination.index);
            setProdcutList(source.droppableId, items, null, null);

        } else if (source.droppableId !== destination.droppableId) {
            const sourceBefore = getProductList(source.droppableId);
            const desitinationBefore = getProductList(destination.droppableId);
            
            if (source.droppableId === 'products'){
                
            }

            const result = move(
                sourceBefore,
                desitinationBefore,
                source,
                destination
            );

            if (source.droppableId !== 'products'){
                let difference = arrayDiffByKey('id', sourceBefore, result[source.droppableId]);
                categoryAPI.removeProduct(source.droppableId, difference[0].id);
            }

            if (destination.droppableId !== 'products'){
                let difference = arrayDiffByKey('id', desitinationBefore, result[destination.droppableId]);
                categoryAPI.addProduct(destination.droppableId, difference[0].id, destination.index);
            }

            setProdcutList(source.droppableId, result[source.droppableId] || [], destination.droppableId, result[destination.droppableId] || []);            
        }
    };

    const arrayDiffByKey = (key, ...arrays) => {
        return [].concat(...arrays.map( (arr, i) => {
            const others = arrays.slice(0);
            others.splice(i, 1);
            const unique = [...new Set([].concat(...others))];
            return arr.filter( x =>
                !unique.some(y => x[key] === y[key])
            );
        }));
    }

    const searchMultiLang = (p, searchStr) => {
        if (!searchStr || searchStr.length === 0)
            return true;

        let r = Object.values(p.data.name).filter(n => n && n.length > 0 && n.toLowerCase().indexOf(searchStr.toLowerCase()) > -1);
        return r.length > 0;
    }

    const onProductEdit = (categoryId, productId) => {
        setProductId(productId);
        setOpenProductEdit(true);
    }

    const onProductUpdate = (product) => {
        products.updateProduct(product);

        const newCategories = categories.map(c => {
            if (c.products.some(p => p.id === product.id)){
                return {...c, products: [...c.products.map(p => {
                    if (p.id === product.id)
                        return product;
                    return p;
                })]}
            }
            return c;
        });

        setCategories([...newCategories]);
    }

    return (
        <>
        { loading 
            ? <></>  
            :  <>
            <Product isModalOpen={openProductEdit} setModalOpen={setOpenProductEdit} onSave={onProductUpdate} productId={productId} key={productId}/>
            
            <MenuConfigurationGroup 
                isModalOpen={groupModalOpen} 
                setModalOpen={setGroupModalOpen} 
                onSuccess={onSuccessGroupSave}
                onFail={onFailGroupSave}
                group={selectedGroup} 
                menuId={props.data.id} 
                key={props.data.id}
                />
            
            
            <div className=''>
            <Layout style={{height:"100%", overflow: "auto", marginTop: "30px", backgroundColor: '#f0f2f5', }} className=''>
            <DragDropContext onDragEnd={onDragEnd} className=''>
            <div className='flex' style={{maxWidth: "600px"}}>
                {
                    hideConfigColumns == false
                    ?  <div>
                            <Droppable 
                                droppableId={`group_columns_2`}
                                direction="vertical"
                                type="group"
                                key={`group_columns_2`}
                            >
                                {(provided, snapshot) => (
                            
                                <div
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    <Card 
                                        size="small" 
                                        title={`Group list`}
                                        extra={
                                            <Button style={{width:"130px", float:"right"}} onClick={() => createGroup(null)} type="primary" icon={<GroupOutlined />} key="group_button">New Group</Button>
                                        }
                                        style={{ width: "250px", margin: "10px 0px", overflowY: "auto", borderRadius: "10px" }}
                                    >
                                    {
                                    categories.map((c, index) => 
                                            <MenuCategoryColumn products={productList} language={company.language} shortCategory={true} category={c} onEdit={createGroup} onDelete={deleteGroup} onProductVisibilityChange={onProductVisibilityChange} onCategoryVisibilityChange={onCategoryVisibilityChange} index={index} onProductButtonAdd={onProductButtonAdd} onProductButtonRemove={onProductButtonRemove}/>   
                                        )
                                    }
                                </Card>
                                </div>
                                
                                )}
                            </Droppable>
                        </div>
                    : <></>
                }
               
                <div>
                    <Droppable 
                        droppableId={`group_columns`}
                        direction="horizontal"
                        type="group"
                        key={`group_columns`}
                    >
                        {(provided, snapshot) => (
                            
                                <div
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                        <Row wrap={false} style={{width:"100%", overflow:"auto"}} className="">
                            {
                                hideConfigColumns == false
                                ? <Col>
                                <Card 
                                        size="small" 
                                        title={`Products available`}
                                        style={{ width: "250px", maxHeight: "800px", margin: "10px 0px", overflowY: "auto", borderRadius: "10px" }}
                                        extra={
                                            <Button style={{float:"right"}} onClick={() => setHideConfigColumns(true)} type="primary" icon={<GroupOutlined />} key="group_button"></Button>
                                        }
                                >
                                    <Input.Search onChange={ (e) => setProductSearchText(e.target.value) }/>
                                    
                                    <Droppable droppableId="products" key="products" type="product">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}>
                                                { productList.filter(p => searchMultiLang(p, productSearchText)).map((item, index) => 
                                                    <div className="flex flex-col">
                                                    <Draggable
                                                        key={item.id}
                                                        draggableId={`${item.id}_products`}
                                                        index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                key={`${item.id}_draggable_products`}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}
                                                            >
                                                                {provided.placeholder}
                                                                <div className="rounded-lg shadow-md">
                                                                    <div>
                                                                        {/*
                                                                            item.data.images && item.data.images.length > 0 
                                                                            ? <img className="rounded-t-lg object-cover w-full h-16" src={item.data.images[0].url} />
                                                                            : ''*/
                                                                        }
                                                                        
                                                                    </div>
                                                                    <div className="px-3 pt-3">{item.data.name && item.data.name[company.language]}</div>
                                                                    <div className="px-3 pt-1 pb-3">{formatCurrencty(item.data.gross, company.currency)}</div>
                                                                </div>
                                                            </div>
                                                
                                                        )}
                                                    </Draggable>
                                                    </div>
                                                ) }
                                        
                                        </div>
                                        )}
                                    
                                    </Droppable>
                                </Card>
                            </Col>
                            : <><Button style={{float:"right"}} onClick={() => setHideConfigColumns(false)} type="primary" icon={<GroupOutlined />} key="group_button"></Button></>
                            }
                            
                            {
                                categories.map((c, index) => 
                                    <Col>
                                    <MenuCategoryColumn products={productList} productEdit={onProductEdit} language={company.language} category={c} shortCategory={false} onEdit={createGroup} onDelete={deleteGroup} onProductVisibilityChange={onProductVisibilityChange} onCategoryVisibilityChange={onCategoryVisibilityChange} index={index} onProductButtonAdd={onProductButtonAdd} onProductButtonRemove={onProductButtonRemove} />   
                                    </Col>
                                )
                            }
                            
                        </Row>
                        </div>
                        )}
                        
                    </Droppable>
                </div>
            </div>
            
           
            </DragDropContext>
            </Layout>
            </div>
            </>
        }

       
        </>
    )
}